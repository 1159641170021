import {useState} from 'react';
import {
  BrowserRouter as Router,
  useSearchParams,
} from "react-router-dom";

function App() {
  return (
    <Router>
      <Main />
    </Router>
  );
}

function Main() {
  let [searchParams] = useSearchParams();

  return (
    <div className="flex flex-col items-center px-6">
      <a href="/" className="max-w-lg text-xl mt-10"><span className="font-black">WebRep</span> domains</a>

      {searchParams.get('success') === 'true' ? <Success /> : <Default />}

      <div className="flex justify-between max-w-4xl w-full text-sm text-gray-500 border-t border-slate-200 mt-10 py-10 px-6">
        <p>Copyright © {(new Date().getFullYear())} WebRep</p>
        <a href="mailto:contact@webrep.app" className="hover:text-gray-400">Contact</a>
      </div>
    </div>
  );
}

function Default() {
  let [volume, setVolume] = useState(200);
  let [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <div className="text-center max-w-4xl my-10">
        <h1 className="max-w-3xl mx-auto text-3xl font-extrabold tracking-tight text-gray-900 leading-10 sm:text-4xl sm:leading-none md:text-5xl">Available high-quality <span className="text-blue">.com domains</span>, directly in your inbox</h1>
        <p className="max-w-md mx-auto mt-3 text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">Searching for domain name ideas? Our AI-powered algorithms generate first-class domain names that are guaranteed to be available. They are unique, catchy, and easy to spell.</p>
      </div>

      <form action="https://muq74zyp2j.execute-api.us-east-1.amazonaws.com/default/domain-generator-stripe" method="POST" className="flex flex-col max-w-4xl w-full mb-10" onSubmit={e => setIsLoading(true)}>
        <div className="w-full px-6 py-8 bg-white rounded-lg shadow-lg">
          <div className="flex justify-between w-full">
            <div>
              <p className="text-sm font-medium tracking-wider text-gray-500 uppercase leading-6">Domain names</p>
              <p className="text-2xl font-extrabold leading-none text-gray-900">{volume}</p>
            </div>
            <div className="text-right">
              <p className="text-sm font-medium tracking-wider text-gray-500 uppercase leading-6">Your price</p>
              <p className="text-2xl font-extrabold leading-none text-gray-900">${0.02 * volume}</p>
            </div>
          </div>
          <input value={volume} onChange={e => setVolume(e.target.value)} type="range" name="volume" min="200" max="5000" step="100" className="mt-8 w-full" />
        </div>
        <button disabled={isLoading ? true : false} type="submit" className="mx-auto flex items-center justify-center mt-8 px-8 py-3 text-base font-medium text-white bg-blue border border-transparent leading-6 rounded-md hover:bg-blue-lighter focus:outline-none focus:shadow-outline-blue transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10 disabled:opacity-50">{isLoading ? <div><svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg></div> : null}Find domain names</button>
      </form>

      <div className="flex flex-col align-items max-w-4xl w-full mt-10 mb-10 space-y-6 text-gray-900 text-sm">
        <p className="text-sm font-medium tracking-wider text-gray-500 uppercase leading-6 text-center">Recently generated domains</p>
        <ul className="columns-4 text-center opacity-75">
          <li>shovelet.com</li>
          <li>aethal.com</li>
          <li>suspicial.com</li>
          <li>lukeless.com</li>
        </ul>
        <ul className="columns-4 text-center opacity-50">
          <li>carkless.com</li>
          <li>jollinic.com</li>
          <li>hystric.com</li>
          <li>geodesman.com</li>
        </ul>
        <ul className="columns-4 text-center opacity-30">
          <li>ceasmic.com</li>
          <li>agistry.com</li>
          <li>diplosive.com</li>
          <li>linearish.com</li>
        </ul>
        <ul className="columns-4 text-center opacity-10">
          <li>diplasian.com</li>
          <li>engores.com</li>
          <li>monomorphous.com</li>
          <li>monotomy.com</li>
        </ul>
      </div>
    </>
  );
}

function Success() {
  return (
    <>
      <div className="text-center max-w-4xl my-10">
        <h1 className="max-w-2xl mx-auto text-3xl font-extrabold tracking-tight text-gray-900 leading-10 sm:text-4xl sm:leading-none md:text-5xl">Thank you for your order!</h1>
        <p className="max-w-md mx-auto mt-3 text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">Our AI is cooking! Your domain names are being prepared right this instant.</p>
        <p className="max-w-md mx-auto mt-3 text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">An email containing the list of domains will be sent within 5 minutes to the address you specified during checkout. Make sure to also check your spam folder.</p>
        <p className="max-w-md mx-auto mt-3 text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">Still haven't received it? Please contact us at <a href="mailto:contact@webrep.app" className="text-blue hover:underline hover:text-blue-lighter">contact@webrep.app</a>.</p>
      </div>
      <a href="/" className="mx-auto flex items-center justify-center mt-8 px-8 py-3 text-base font-medium text-white bg-blue border border-transparent leading-6 rounded-md hover:bg-blue-lighter focus:outline-none focus:shadow-outline-blue transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">Back to the home page</a>
    </>
  );
}

export default App;
